<template>
  <!-- 党建工作 -->
  <div class="party">
    <my-banner :banner="banner" :nav="[]" />
    <my-notice :id="4" />
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";
export default {
  name: "party",
  data() {
    return {
      banner: "",
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "党建工作",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
};
</script>
